import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import Amplify, { API } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);


class App extends Component {
  async componentDidMount() {
    try {
      const apiResponse = await API.get('amplifyrestapi', '/api')
      console.log(apiResponse)
      // this.setState({ people: peopleData.people })
    } catch (err) {
      console.log('error fetching from Lambda API')
    }
  }
  render() {
    return (
      <div className="App">

          <AmplifySignOut />
          My App
      </div>
    );
  }
}

export default withAuthenticator(App);
